<template>
  <div class="mepromise">
    <div>
      <div class="signature">
        <div id="canvas"></div>
      </div>
      <div class="canvas-btn">
        <button class="btnfh" @click="goBack">返回上页</button>

        <button id="clearCanvas">重新签名</button>
      </div>
    </div>
    <div class="signature2">
      <div class="signature2-title">
        <div class="ctx-title">
          <div class="title-ctx">我承诺</div>
          <div class="title-fk"></div>
        </div>
      </div>
      <div class="chengnuo">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
          alt=""
        />
        <div>每天用一点时间来探索和理解自己的情绪</div>
      </div>

      <div class="chengnuo">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
          alt=""
        />
        <div>没有时间练习的时候，我也会照顾好自己，和情绪好好相处</div>
      </div>

      <div class="chengnuo">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
          alt=""
        />
        <div>用心地体会和了解我的情绪，对它多一点耐心</div>
      </div>

      <div class="chengnuo">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
          alt=""
        />
        <input type="text" v-model="words" placeholder="其他你想要的补充" />
      </div>

      <div class="submit">
        <button id="saveCanvas" @click="saveSign">确定保存</button>
      </div>
      <img style="width: 100%" :src="imgBase64" alt="" />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { setPromise, setComplated } from "../../api/login";
export default {
  data() {
    return {
      img: "",
      words: "",
      imgBase64: "",
      id: "id",
    };
  },
  mounted() {
    this.init_lineCanvas();
    this.item = this.$route.query.item;
    this.id = this.$route.query.item.id;

    console.log("拿到的列表信息", this.item);
  },
  methods: {
    //点击完成
    onsetComplated() {
      let data = { id: this.id, userId: window.localStorage.getItem("userId") };
      setComplated(data).then((res) => {
        this.wcshow = !this.wcshow;
      });
    },
    goBack() {
      this.$router.go(-1);
    },

    // 保存签名
    saveSign() {
      setTimeout(() => {
        this.imgBase64 = window.localStorage.getItem("imgBase64");
        console.log(this.imgBase64, " this.imgBase64");
        this.submit();
      }, 500);
    },
    init_lineCanvas() {
      document.addEventListener(
        "touchmove",
        function (event) {
          event.preventDefault();
        },
        { passive: false }
      );
      new this.lineCanvas({
        el: document.getElementById("canvas"), // 绘制canvas的父级div
        clearEl: document.getElementById("clearCanvas"), // 清除按钮
        saveEl: document.getElementById("saveCanvas"), // 保存按钮
        linewidth: 3, // 线条粗细，选填
        color: "black", // 线条颜色，选填
        background: "#ffffff", // 线条背景，选填
      });
    },
    /* 电子签名手写板 */
    lineCanvas(obj) {
      this.linewidth = 1;
      this.color = "#000000";
      this.background = "#ffffff";
      for (var i in obj) {
        this[i] = obj[i];
      }
      this.canvas = document.createElement("canvas");
      this.el.appendChild(this.canvas);
      this.cxt = this.canvas.getContext("2d");
      this.canvas.width = 417;
      //   this.canvas.height = 207;
      //   this.cxt.fillStyle = this.background;
      //   this.cxt.fillRect(0, 0, this.canvas.width, this.canvas.width);
      //   this.cxt.strokeStyle = this.color;
      //   this.cxt.lineWidth = this.linewidth;

      this.cxt.lineCap = "round";
      // 开始绘制
      this.canvas.addEventListener(
        "touchstart",
        function (e) {
          console.log("开始绘制");
          this.cxt.beginPath();
          this.cxt.moveTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
        }.bind(this),
        false
      );
      // 绘制中
      this.canvas.addEventListener(
        "touchmove",
        function (e) {
          this.cxt.lineTo(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
          this.cxt.stroke();
        }.bind(this),
        false
      );
      // 结束绘制
      this.canvas.addEventListener(
        "touchend",
        function () {
          console.log("结束绘制");
          this.cxt.closePath();
        }.bind(this),
        false
      );
      // 清除画布
      this.clearEl.addEventListener(
        "click",
        function () {
          console.log("清除画布");
          this.cxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }.bind(this),
        false
      );
      document.getElementById("clearCanvas").click();
      // 保存图片，直接转base64
      this.saveEl.addEventListener(
        "click",
        function () {
          console.log("this.canvas.toDataURL()", this.canvas.toDataURL());
          var imgBase64 = this.canvas.toDataURL();
          console.log("保存签名成功" + imgBase64);
          window.localStorage.setItem("imgBase64", imgBase64);
          this.img = this.canvas.toDataURL();
        }.bind(this),
        false
      );
    },

    submit() {
      console.log("this.imgBase64", this.imgBase64);
      let data = {
        userId: window.localStorage.getItem("userId"),
        words: this.words,
        sginUrl: this.imgBase64,
      };
      setPromise(data).then((res) => {
        this.onsetComplated();
        Toast.success("签署成功");
        this.$router.push("/setout/check");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.mepromise {
  background-color: #fff;
  min-height: 100vh;
}
.signature2 {
  padding: 0 20px;
  box-sizing: border-box;
}
.ctx-title {
  .title-ctx {
    z-index: 999;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #000025;
    line-height: 25px;
  }
  .title-fk {
    width: 35px;
    height: 5px;
    background: #629ee9;
    position: relative;
    bottom: 8px;
    z-index: 998;
    transform: skew(-25deg);
  }
}
.chengnuo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  img {
    width: 12px;
  }
  input {
    width: 100%;
    border-radius: 10px;
    height: 34px;
    border: 1px solid #e0e0e0;
    color: #333;
    padding: 0 20px;
  }
}
.qianshu {
  width: 155px;
  height: 58px;
  border: 1px solid #e0e0e0;
  color: #e0e0e0;
  font-size: 12px;
  border-radius: 10px;
  float: right;
  margin-top: 50px;
  padding: 10px;
}

.signature2-title {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.signature {
  background: #333;
  position: relative;
  overflow: hidden;
}

#canvas canvas {
  display: block;
  background: #f3f3f3;
  border-radius: 8px;
  margin: 0 auto;
}

#clearCanvas {
  border: 0;
  font-size: 17px;
  color: #ffffff;
  background-color: #629ee9;
  height: 28px;
  width: 100px;
  border-radius: 25px;
}
.btnfh {
  border: 0;
  font-size: 17px;
  color: #ffffff;
  background-color: #629ee9;
  height: 28px;
  width: 100px;
  border-radius: 25px;
}

.submit {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  button {
    border: 0;
    font-size: 17px;
    color: #ffffff;
    background-color: #629ee9;
    height: 48px;
    width: 150px;
    border-radius: 25px;
  }
}
.canvas-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
</style>
